import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, Validators } from '@angular/forms';
import {
  IAvansnaUplataRacunaTableDto,
  IAvansniRacunTableDto,
  IGetFormConfigurationResponse,
  INeobradjenRacunDto,
  InformacijaIsporukeDto,
  IOznakaDto,
  IPredmetStavkeDto,
  IRacunDto,
  IRacunUkupanIznosDto,
  ISastavnicaDto,
  IStavkaRacunaDto,
  IStrankaRacunaDto,
  IVezaniRacunDto,
  JedinicaMereDto,
  OznakaDto,
  OznakaPdvKategorije,
  PodaciUplateDto,
  StavkaRacunaDto,
  StrankaRacunaDto,
  TipPredmeta,
  TipRacuna,
  TipStranke,
  VrstaRacuna,
} from '@kodit/core/data-api';
import {
  AvansnaUplataRacunaFormService,
  OznakaFormService,
  StavkaFormService,
  VezaniRacunFormService,
} from '@kodit/core/form-definitions';
import { BaseService, TIP_RACUNA_TO_TIP_ENTITETA } from '@kodit/core/services';
import {
  multiplyIfNotNullConditionally,
  SERBIA_CURRENCY_ALPHA_CHAR,
  StopaPdv,
  subIfNotNull,
  sumIfNotNull,
} from '@kodit/core/shared';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { StrankaRacunaFormService } from 'libs/core/form-definitions/src/lib/racun/stranka-racuna-form.service';
import { BaseRacunFormService } from 'libs/core/form-definitions/src/lib/racun/_base_racun-form.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

const OSNOVNA_PDV_STOPA = 20;
const POSEBNA_PDV_STOPA = 10;
const POSEBNA_PDV_STOPA_POLJOPRIVREDNO_GAZDINSTVO = 8;

@Injectable({
  providedIn: 'root',
})
export class RacunService extends BaseService implements OnDestroy {
  private _subs: Subscription = new Subscription();

  constructor(
    private _avansnaUplataRacunaFS: AvansnaUplataRacunaFormService,
    private _vezaniRacunFS: VezaniRacunFormService,
    private _stavkeFS: StavkaFormService,
    private _oznakaFS: OznakaFormService,
    private _racunFS: BaseRacunFormService,
    private _strankaRacunaFS: StrankaRacunaFormService
  ) {
    super();
  }

  /**
   * Postavljanje forme racuna
   */
  racunForm$ = new BehaviorSubject<RacunForm>(null);

  set setRacunForm(value: RacunForm) {
    this.racunForm$.next(value);
    this.setJeDomaciRacun = value?.value.valutaText
      ? value.value.valutaText === SERBIA_CURRENCY_ALPHA_CHAR
      : true;

    if (value?.value.avansneUplateRacunaTableDto.length) {
      this.setAvansneUplateRacuna = value?.value.avansneUplateRacunaTableDto;
    }

    // provera ako je edit, da postavimo koriscene stavke
    if (value?.value?.stavkeDto.length > 0) {
      this.addedPredmetiStavki$.next([
        ...value.value.stavkeDto
          .filter((x) => x.id > 0)
          .map((x) => x.predmetStavkeDto.id),
      ]);
    }
    this._updateHasVezaniRacun();
  }

  get getRacunFormObject(): RacunForm {
    return this.racunForm$.getValue();
  }

  //#region flags

  /**
   * Postavljanje labele za broj racuna
   */
  jeDomaciRacun$ = new BehaviorSubject<boolean>(true);

  set setJeDomaciRacun(value: boolean) {
    this.jeDomaciRacun$.next(value);
    if (value) {
      this._clearDevizniRacunValidation();
    } else {
      this._setDevizniRacunValidation();
      //this.setCurrencyMiddleRate = this.getRacunFormObject.value.podaciUplateDevizniDto.srednjiKursNaDan;
    }
  }

  get getJeDomaciRacun() {
    return this.jeDomaciRacun$.asObservable();
  }

  get shouldAllowAvansneAkcije() {
    // ! akcije su dozvoljene samo pri unosu
    // if (!this.getRacunFormObject) {
    //   return false;
    // }
    // return (
    //   this.getRacunFormObject.value.id == null ||
    //   this.getRacunFormObject.value.id === 0
    // );

    // ! Ovaj deo koda ce nam dozvoliti i edit avansnih uplata racuna, medjutim imamo
    // ! zezanciju sa tim jer moramo da ponovo odradimo fetch ka bazi, a malo je i zeznuto,
    // ! jer moramo voditi racuna koliko je iskorisceno sa tog avansnog racuna i to da uzmemo
    // ! u obzir pri samoj izmeni...
    return !!this.getRacunFormObject !== false;
  }

  get getIsRacunValid(): boolean {
    if (!this.getRacunFormObject) {
      return false;
    }
    return this.getRacunFormObject.valid;
  }

  //#endregion flags

  //#region oznake

  /**
   * Postavljanje oznaka od izabranih predmeta stavki
   */
  oznakePredmetaStavki$ = new BehaviorSubject<OznakaDto[]>([]);

  set setOznakePredmetaStavke(value: OznakaDto[]) {
    this.oznakePredmetaStavki$.next(value);
  }

  get getOznakePredmetaStavke() {
    return this.oznakePredmetaStavki$.asObservable();
  }

  /**
   * Postavljanje oznaka za brisanje
   */
  oznakeForDelete$ = new BehaviorSubject<number>(null);

  set setOznakeForDelete(value: number) {
    this.oznakeForDelete$.next(value);
  }

  get getOznakeForDelete() {
    return this.oznakeForDelete$.asObservable();
  }

  get getOznakeArray(): FormArray {
    if (!this.getRacunFormObject) {
      return new FormArray([]);
    }
    return this.getRacunFormObject.controls.oznakeDto as FormArray;
  }

  tipRacunaForFileManager$: BehaviorSubject<TipRacuna> = new BehaviorSubject<TipRacuna>(
    TipRacuna.IZLAZNA_FAKTURA
  );

  set setTipRacunaFileManager(tipRacuna: TipRacuna) {
    this.tipRacunaForFileManager$.next(tipRacuna);
  }

  get getTipRacunaFileManager() {
    return this.tipRacunaForFileManager$.asObservable();
  }

  /**
   * Dodaje oznaku u listu oznaka za racun samo ukoliko vec nije u listi
   * @param oznaka oznaka za dodavanje
   */
  addOznakuRacuna(oznaka: OznakaDto) {
    if (
      !(this.getOznakeArray.value as OznakaDto[]).some(
        (x) => x.id === oznaka.id
      )
    ) {
      this.getOznakeArray.push(this._oznakaFS.GetOznakaFormGroup(oznaka));
    }
  }

  //#endregion oznake

  //#region getters and setters

  /**
   * Privremeni CustomObject koji se koristi za prosledjivanje vrednosti izmedju komponenti bez obzira na tip
   */

  customObject$ = new BehaviorSubject<any>(null);

  set setCustomObject(value: any) {
    this.customObject$.next(value);
  }

  get getCustomObject() {
    return this.customObject$.asObservable();
  }

  getCustomObjectValue(): any {
    return this.customObject$.value;
  }

  resetCustomObjectValue() {
    this.setCustomObject = null;
  }

  /**Postavljanje neto tezine robe na racunu */

  netoTezina$ = new BehaviorSubject<number | null>(null);

  set setNetoTezina(value: number) {
    this.netoTezina$.next(value);
  }

  get getNetoTezina() {
    return this.netoTezina$.asObservable();
  }

  updateUkupnaNetoTezinaForStavke() {
    this.setNetoTezina = 0;

    this.getStavkeArray.controls.forEach((x) => {
      const kolicina = x.get('kolicina').value as number;
      const netoTezinaKg = x.get('predmetStavkeDto.netoTezinaKg')
        .value as number;

      var updatedValue = this.netoTezina$.getValue() + kolicina * netoTezinaKg;
      this.setNetoTezina = updatedValue;
    });
  }

  resetNetoTezina(value: number) {
    this.setNetoTezina = value;
  }

  /**
   * Postavljanje informacije da li treba da prikazemo dialog za unos fizickog lica
   */
  showFizickoLiceCreate$ = new BehaviorSubject<string>(null);

  set setShowFizickoLiceCreate(value: string) {
    this.showFizickoLiceCreate$.next(value);
  }

  get getShowFizickoLiceCreate() {
    return this.showFizickoLiceCreate$.asObservable();
  }

  /**
   * Postavljanje vrednost srednjeg kursa za valutu koja je odabrana na formi racuna.
   */
  currencyMiddleRate$ = new BehaviorSubject<number>(null);

  set setCurrencyMiddleRate(value: number) {
    this.currencyMiddleRate$.next(value);
    this.racunForm$.value.controls.podaciUplateDevizniDto
      .get('srednjiKursNaDan')
      .patchValue(value);
    this.calculateUkupno();
  }

  get getCurrencyMiddleRate() {
    return this.currencyMiddleRate$.asObservable();
  }

  /**
   * Postavljanje informacije da li treba da odradimo automatski search fizickog lica
   */
  performSearchFizickogLica$ = new BehaviorSubject<string>(null);

  set setPerformSearchFizickogLica(value: string) {
    this.performSearchFizickogLica$.next(value);
  }

  get getPerformSearchFizickogLica() {
    return this.performSearchFizickogLica$.asObservable();
  }

  /**
   * Postavljanje labele za broj racuna
   */
  brojRacunaLabel$ = new BehaviorSubject<string>('Broj računa');

  set setBrojRacunaLabel(value: string) {
    this.brojRacunaLabel$.next(value);
  }

  get getBrojRacunaLabel() {
    return this.brojRacunaLabel$.asObservable();
  }

  razlogIzuzecaIdGlobalni$ = new BehaviorSubject<number | null>(null);

  set setRazlogIzuzecaIdGlobalni(value: number) {
    this.razlogIzuzecaIdGlobalni$.next(value);
    this.setRazlogIzuzecaChanged = true;
  }

  get getRazlogIzuzecaIdGlobalni() {
    return this.razlogIzuzecaIdGlobalni$.asObservable();
  }

  razlogIzuzecaChanged$ = new BehaviorSubject<boolean>(false);

  set setRazlogIzuzecaChanged(value: boolean) {
    this.razlogIzuzecaChanged$.next(value);
  }

  get getRazlogIzuzecaChanged() {
    return this.razlogIzuzecaChanged$.asObservable();
  }

  resetRazlogIzuzecaIdGlobalni() {
    this.setRazlogIzuzecaIdGlobalni = null;
  }

  pdvKategorijaGlobalna$ = new BehaviorSubject<OznakaPdvKategorije | null>(
    null
  );

  set setPdvKategorijaGlobalna(value: OznakaPdvKategorije) {
    this.pdvKategorijaGlobalna$.next(value);
  }

  get getPdvKategorijaGlobalna() {
    return this.pdvKategorijaGlobalna$.asObservable();
  }

  resetPdvKategorijaGlobalna() {
    this.setPdvKategorijaGlobalna = null;
  }

  set setReferentniDokument(datum: Date) {
    this.getRacunFormObject.controls.datumNarudzbenice.patchValue(datum);
    this.getRacunFormObject.controls.datumNarudzbenice.updateValueAndValidity();
  }

  /**
   * Postavljanje Cena po JM za stavku racuna
   */

  nabavnaCena$ = new BehaviorSubject<number | null>(null);

  set setNabavnaCena(value: number) {
    this.nabavnaCena$.next(value);
  }

  get getNabavnaCena() {
    return this.nabavnaCena$.asObservable();
  }

  prodajnaCena$ = new BehaviorSubject<number | null>(null);

  set setProdajnaCena(value: number) {
    this.prodajnaCena$.next(value);
  }

  get getProdajnaCena() {
    return this.prodajnaCena$.asObservable();
  }

  marza$ = new BehaviorSubject<number | null>(null);

  set setMarza(value: number) {
    this.marza$.next(value);
  }

  get getMarza() {
    return this.marza$.asObservable();
  }

  updateMarzaValue(value: number) {
    this.setMarza = value;
  }

  //#endregion getters and setters

  //#region getters

  get getPrikazRabata(): AbstractControl {
    return this.getRacunFormObject.controls.prikazRabata;
  }

  get getValutaTextValue(): string {
    if (!this.getValutaText) {
      return null;
    }
    return this.getValutaText.value;
  }

  get getValutaText(): AbstractControl {
    if (!this.getRacunFormObject) {
      return null;
    }
    return this.getRacunFormObject.controls.valutaText;
  }

  get getTipRacuna(): TipRacuna {
    if (!this.getRacunFormObject) {
      return TipRacuna.EMPTY;
    }
    return this.getRacunFormObject.value.tip;
  }

  get getVrstaRacuna(): VrstaRacuna {
    if (!this.getRacunFormObject) {
      return VrstaRacuna.EMPTY;
    }
    return this.getRacunFormObject.value.vrsta;
  }

  //#endregion common getters

  //#region  Stavke
  get getStavkeArray(): FormArray {
    if (!this.getRacunFormObject) {
      return new FormArray([]);
    }
    return this.getRacunFormObject.controls.stavkeDto as FormArray;
  }

  getStavkaByIndex(index: number): StavkeRacunaForm {
    if (!this.getRacunFormObject) {
      return null;
    }

    return this.getRacunFormObject
      .getSafe<IStavkaRacunaDto[]>((x) => x.stavkeDto)
      .get([index]) as StavkeRacunaForm;
  }

  addedPredmetiStavki$ = new BehaviorSubject<number[]>(null);

  get getAddedPredmetiStavki() {
    return this.addedPredmetiStavki$.asObservable();
  }

  set setAddedPredmetiStavki(value: number[]) {
    this.addedPredmetiStavki$.next(value);
  }

  handleKalkulacijaCena(
    predmetstavke: IPredmetStavkeDto,
    stavkaIndex: number,
    prodajnaCenaBezPdv: number
  ) {
    this.patchPredmetStavke(predmetstavke, stavkaIndex);
    this.getStavkaByIndex(stavkaIndex).controls.prodajnaCenaBezPdv.patchValue(
      prodajnaCenaBezPdv
    );
  }

  updateStavkaRacuna(
    stavkaIdx: number,
    predmetStavke: IPredmetStavkeDto,
    kolicina: number,
    jedinicaMere: JedinicaMereDto,
    magacinId?: number,
    razlogPdvIzuzecaId?: number,
    oznakaPdvKategorije?: number
  ) {
    this.getStavkaByIndex(stavkaIdx).controls.naziv.patchValue(
      predmetStavke.naziv
    );
    this.getStavkaByIndex(stavkaIdx).controls.magacinId.patchValue(
      magacinId ?? null
    );
    this.getStavkaByIndex(stavkaIdx).controls.jedinicaMere.patchValue(
      jedinicaMere
    );
    this.getStavkaByIndex(stavkaIdx).controls.nabavnaCenaBezPdv.patchValue(
      predmetStavke.nabavnaCena
    );
    this.getStavkaByIndex(stavkaIdx).controls.prodajnaCenaBezPdv.patchValue(
      predmetStavke.prodajnaCenaBezPdv
    );
    this.getStavkaByIndex(stavkaIdx).controls.predmetStavkeDto.patchValue(
      predmetStavke
    );
    this.getStavkaByIndex(stavkaIdx).controls.stopaPDV.patchValue(
      predmetStavke.stopaPDV
    );
    this.getStavkaByIndex(stavkaIdx).controls.razlogPdvIzuzecaId.patchValue(
      razlogPdvIzuzecaId ?? null
    );
    this.getStavkaByIndex(stavkaIdx).controls.pdvKategorija.patchValue(
      oznakaPdvKategorije ?? null
    );
    this.getStavkaByIndex(stavkaIdx).controls.kolicina.patchValue(kolicina);

    this.setOznakePredmetaStavke = predmetStavke.oznakeDto;
    this.updateStavkaRacunaValidity(
      stavkaIdx,
      predmetStavke.tipPredmeta === TipPredmeta.ROBA
    );

    // sa novom izmenom za Default razlogPdvIzuzeca je nepotrebno
    // if (predmetStavke.stopaPDV === 0) {
    //   this.updatePdvKategorijuZaStavku(stavkaIdx);
    //   this.updateRazlogPdvIzuzecaZaStavku(stavkaIdx);
    // }
  }

  updateRazlogPdvIzuzecaZaStavku(stavkaIdx: number) {
    this.getStavkaByIndex(stavkaIdx).controls.razlogPdvIzuzecaId.patchValue(
      this.razlogIzuzecaIdGlobalni$.value
    );
    this.getStavkaByIndex(stavkaIdx).controls.razlogPdvIzuzecaId.addValidators([
      Validators.required,
    ]);
    this.getStavkaByIndex(
      stavkaIdx
    ).controls.razlogPdvIzuzecaId.updateValueAndValidity();
  }

  updatePdvKategorijuZaStavku(stavkaIdx: number) {
    this.getStavkaByIndex(stavkaIdx).controls.pdvKategorija.patchValue(
      this.pdvKategorijaGlobalna$.value
    );
    this.getStavkaByIndex(stavkaIdx).controls.pdvKategorija.addValidators([
      Validators.required,
    ]);
    this.getStavkaByIndex(
      stavkaIdx
    ).controls.pdvKategorija.updateValueAndValidity();
  }

  resetRazlogPdvIzuzecaZaStavku(stavkaIdx: number) {
    this.getStavkaByIndex(stavkaIdx).controls.razlogPdvIzuzecaId.patchValue(
      null
    );
    this.getStavkaByIndex(
      stavkaIdx
    ).controls.razlogPdvIzuzecaId.clearValidators();
    this.getStavkaByIndex(
      stavkaIdx
    ).controls.razlogPdvIzuzecaId.updateValueAndValidity();
  }

  resetPdvKategorijuZaStavku(stavkaIdx: number) {
    this.getStavkaByIndex(stavkaIdx).controls.pdvKategorija.patchValue(
      OznakaPdvKategorije.S
    );
    this.getStavkaByIndex(stavkaIdx).controls.pdvKategorija.clearValidators();
    this.getStavkaByIndex(
      stavkaIdx
    ).controls.pdvKategorija.updateValueAndValidity();
  }

  updateStavkaRacunaValidity(stavkaIdx, jeRoba: boolean) {
    this.getStavkaByIndex(stavkaIdx).controls.naziv.updateValueAndValidity();
    if (jeRoba) {
      this.getStavkaByIndex(stavkaIdx).controls.magacinId.setValidators([
        Validators.required,
      ]);
    } else {
      this.getStavkaByIndex(stavkaIdx).controls.magacinId.setValidators([
        Validators.nullValidator,
      ]);
    }
    this.getStavkaByIndex(
      stavkaIdx
    ).controls.magacinId.updateValueAndValidity();
    this.getStavkaByIndex(
      stavkaIdx
    ).controls.jedinicaMere.updateValueAndValidity();
    this.getStavkaByIndex(
      stavkaIdx
    ).controls.nabavnaCenaBezPdv.updateValueAndValidity();
    this.getStavkaByIndex(
      stavkaIdx
    ).controls.prodajnaCenaBezPdv.updateValueAndValidity();
    this.getStavkaByIndex(
      stavkaIdx
    ).controls.predmetStavkeDto.updateValueAndValidity();
    this.getStavkaByIndex(stavkaIdx).controls.stopaPDV.updateValueAndValidity();
    this.getStavkaByIndex(stavkaIdx).controls.kolicina.updateValueAndValidity();

    if (
      this.getStavkaByIndex(stavkaIdx).controls.stopaPDV.value ===
      StopaPdv.BEZ_PDV
    ) {
      this.getStavkaByIndex(stavkaIdx).controls.pdvKategorija.setValidators([
        Validators.required,
      ]);
      this.getStavkaByIndex(
        stavkaIdx
      ).controls.razlogPdvIzuzecaId.setValidators([Validators.required]);
      this.getStavkaByIndex(
        stavkaIdx
      ).controls.pdvKategorija.updateValueAndValidity();
      this.getStavkaByIndex(
        stavkaIdx
      ).controls.razlogPdvIzuzecaId.updateValueAndValidity();
    } else {
      this.getStavkaByIndex(stavkaIdx).controls.pdvKategorija.setValidators([
        Validators.nullValidator,
      ]);
      this.getStavkaByIndex(
        stavkaIdx
      ).controls.razlogPdvIzuzecaId.setValidators([Validators.nullValidator]);
      this.getStavkaByIndex(stavkaIdx).controls.pdvKategorija.patchValue(
        OznakaPdvKategorije.S
      );
      this.getStavkaByIndex(
        stavkaIdx
      ).controls.pdvKategorija.updateValueAndValidity();
      this.getStavkaByIndex(
        stavkaIdx
      ).controls.razlogPdvIzuzecaId.updateValueAndValidity();
    }
  }

  addStavkuRacuna(stavka: IStavkaRacunaDto, redniBroj: number) {
    this.getStavkeArray.push(
      this._stavkeFS.GetFormGroup({
        stavka: stavka,
        redniBroj: redniBroj,
        isJedinicaMereRequired: true,
      })
    );
  }

  // ! izbacio sam ovu logiku da bi svuda bio isti naziv stavke avansa...
  /*  setNazivStavkeAvansnogRacuna(naziv: string) {
                            this.getStavkaByIndex(0)
                              .controls.predmetStavkeDto.get('naziv')
                              .patchValue(naziv);
                            this.getStavkaByIndex(0)
                              .controls.predmetStavkeDto.get('naziv')
                              .updateValueAndValidity();
                          }*/

  //#endregion  Stavke

  //#region Materijali

  /** vraca nam sve materijale za stavku
   * @return FormArray materijala stavke */
  getMaterijalArrayForStavka(stavkaIndex: number): FormArray {
    return this.getStavkaByIndex(stavkaIndex)?.controls.materijali as FormArray;
  }

  /** postavlja nam materijal(e) u stavku */
  setMaterijalForStavka(
    stavkaIdx: number,
    kolicina: number,
    sastavnicaDtoList: ISastavnicaDto[],
    isStrankaUSistemuPDVa: boolean
  ) {
    this.getMaterijalArrayForStavka(stavkaIdx).clear();
    sastavnicaDtoList.forEach((s, idx) => {
      this._addMaterijalToStavka(stavkaIdx, idx, s, isStrankaUSistemuPDVa, kolicina);
    });
  }

  /** dodaje 1 materijal u stavku */
  private _addMaterijalToStavka(
    stavkaIdx: number,
    materijalIdx: number,
    materijal: ISastavnicaDto,
    isStrankaUSistemuPDVa: boolean,
    kolicina: number
  ) {
    this.getMaterijalArrayForStavka(stavkaIdx).push(
      this._stavkeFS.GetFormGroup({
        redniBroj: materijalIdx,
        stavka: {
          naziv: materijal.predmetStavkeDto.naziv,
          jedinicaMere: materijal.predmetStavkeDto.jedinicaMereDto,
          nabavnaCenaBezPdv: materijal.predmetStavkeDto.nabavnaCena,
          predmetStavkeDto: materijal.predmetStavkeDto,
          prodajnaCenaBezPdv: materijal.predmetStavkeDto.prodajnaCenaBezPdv,
          stopaPDV: materijal.predmetStavkeDto.stopaPDV,
          kolicina: materijal.kolicina * kolicina,
          iznos:
            materijal.predmetStavkeDto.nabavnaCena +
            (materijal.predmetStavkeDto.nabavnaCena *
              materijal.predmetStavkeDto.stopaPDV) /
              100,
          oznakeDto: materijal.predmetStavkeDto.oznakeDto,
          materijali: materijal.predmetStavkeDto.materijali,
          poreskaOsnovica:
            materijal.predmetStavkeDto.nabavnaCena * (materijal.kolicina * kolicina),
        },

        //isJedinicaMereRequired: ??,
        isStrankaUSistemuPDVa: isStrankaUSistemuPDVa,
      })
    );
  }

  /** vrsi postavljanje nove vrednosti predmeta stavke u form kontrolu */
  patchPredmetStavke(predmetstavke: IPredmetStavkeDto, stavkaIndex: number) {
    this.getStavkaByIndex(stavkaIndex).controls.predmetStavkeDto.patchValue(
      predmetstavke
    );
    if (
      this.getStavkaByIndex(stavkaIndex).get('predmetStavkeDto').value.oznakeDto
        .length === 0
    ) {
      this._patchOznake(stavkaIndex, predmetstavke.oznakeDto);
    }
  }

  //postavljanje vrednosti oznaka ukoliko FormArray oznaka u predmetStavkeDto nije inicijalizovan
  private _patchOznake(stavkaIdx: number, oznakeDto: IOznakaDto[]) {
    oznakeDto.forEach((o) => {
      (this.getStavkaByIndex(stavkaIdx).controls.predmetStavkeDto.get(
        'oznakeDto'
      ) as FormArray).push(this._oznakaFS.GetOznakaFormGroup(o));
    });
  }

  /** uklanja materijal iz stavke */
  removeMaterijalFromStavka(stavkaIdx: number, materijalIdx: number) {
    this.getMaterijalArrayForStavka(stavkaIdx).removeAt(materijalIdx);
    this.getMaterijalArrayForStavka(stavkaIdx).updateValueAndValidity();
  }

  //#endregion Materijali

  //#region Vezani racuni
  get getVezaniRacunArray() {
    if (!this.getRacunFormObject) {
      return null;
    }
    return this.getRacunFormObject.controls.vezaniRacuniDto as FormArray;
  }

  set setVezaniRacunArray(vezaniRacuni: IVezaniRacunDto[]) {
    this.getVezaniRacunArray.clear();
    vezaniRacuni.forEach((a) => this._addVezaniRacun(a));
    this._updateHasVezaniRacun();
  }

  hasVezaniRacun$ = new BehaviorSubject<boolean | null>(null);

  hasVezaniRacun(): Observable<boolean> {
    return this.hasVezaniRacun$.asObservable();
  }

  hasVezaniRacunPoTipuRacuna(array: any, tipRacuna: TipRacuna): boolean {
    return array.some((item) => item.tip === tipRacuna);
  }

  set setHasVezaniRacun(value: boolean) {
    this.hasVezaniRacun$.next(value);
  }

  clearVezaniRacun() {
    this.getVezaniRacunArray.clear();
  }

  private _updateHasVezaniRacun() {
    const hasVezaniRacun: boolean = this.getVezaniRacunArray?.value?.some(
      (x: IVezaniRacunDto) => x.racunId > 0
    );
    this.setHasVezaniRacun = hasVezaniRacun;
  }

  private _addVezaniRacun(vezaniRacun: IVezaniRacunDto) {
    this.getVezaniRacunArray.push(
      this._vezaniRacunFS.getFormGroup(vezaniRacun)
    );
  }

  //#endregion Vezani racuni

  //#region Avansne uplate

  /**
   * Prop koji nam cuva sve ubacene avansne racuna (uplate) na trenutni racun
   */
  odabraneAvansneUplateZaRacunNonModified$ = new BehaviorSubject<
    IAvansniRacunTableDto[]
  >([]);

  poreskaStopaOrKategorijaChanged$ = new BehaviorSubject<boolean>(false);

  set setPoreskaStopaOrKategorijaChanged(value: boolean){
    this.poreskaStopaOrKategorijaChanged$.next(value);
  }

  get getPoreskaStopaOrKategorijaChangedObservable(): Observable<boolean> {
    return this.poreskaStopaOrKategorijaChanged$.asObservable();
  }

  set setOdabraneAvansneUplateZaRacunNonModified(
    value: IAvansniRacunTableDto[]
  ) {
    this.odabraneAvansneUplateZaRacunNonModified$.next(value);
  }

  get getOdabraneAvansneUplateZaRacunNonModified() {
    return this.odabraneAvansneUplateZaRacunNonModified$.asObservable();
  }

  get shouldLoadNonModified(): boolean {
    return (
      this.odabraneAvansneUplateZaRacunNonModified$.value.length === 0 &&
      this.getAvansneUplateRacuna?.length > 0
    );
  }

  get getAvansneUplateRacunaArray(): FormArray {
    if (!this.getRacunFormObject) {
      return new FormArray([]);
    }
    return this.getRacunFormObject.controls
      .avansneUplateRacunaTableDto as FormArray;
  }

  get getAvansneUplateRacuna() {
    return this.getAvansneUplateRacunaArray
      .value as IAvansnaUplataRacunaTableDto[];
  }

  hasRegistrovanuAvansnuUplatu$ = new BehaviorSubject<boolean>(false);

  get getHasRegistrovanuAvansnuUplatu(): Observable<boolean> {
    return this.hasRegistrovanuAvansnuUplatu$.asObservable();
  }

  set setHasRegistrovanuAvansnuUplatu(value: boolean) {
    this.hasRegistrovanuAvansnuUplatu$.next(value);
  }

  jeObrada$ = new BehaviorSubject<boolean>(false);

  get getJeObrada(): Observable<boolean> {
    return this.jeObrada$.asObservable();
  }

  set setJeObrada(value: boolean) {
    this.jeObrada$.next(value);
  }

  set setAvansneUplateRacuna(avansneUplate: IAvansnaUplataRacunaTableDto[]) {
    if (!avansneUplate) {
      return;
    }
    this.getAvansneUplateRacunaArray.clear();
    this._clearAvansiraneIznose();
    avansneUplate.forEach((a) => this._addAvansnuUplatuNaRacun(a));
    this._setAvansneUplateValueAndValidations();
    this.setHasRegistrovanuAvansnuUplatu = avansneUplate.length > 0;
  }

  private _addAvansnuUplatuNaRacun(
    avansnaUplata: IAvansnaUplataRacunaTableDto
  ) {
    this.getAvansneUplateRacunaArray.push(
      this._avansnaUplataRacunaFS.GetAvansneUplateRacunaFormGroup(avansnaUplata)
    );
  }

  /**
   * Za sve avansne uplate na racunu postavljamo validaciju i ukoluko treba odradimo update vrednosti
   * Update se radi jer ne mozemo da evidentiramo vecu uplatu nego sto je iznos racuna
   * Po svakoj poreskoj kategoriji kroz sve registrovane avanse...
   */
  private _setAvansneUplateValueAndValidations() {
    /**
     * Ne radi nam kada imamo vise avansnih uplata sa istom stopom !!
     * Za Avansirana osnovica - stopa 20% stavi iznos sa prve avansne uplate, tako isto i za pdv...
     *
     */

    this.getAvansneUplateRacunaArray.controls.forEach(
      (avansnaUplata: AvansnaUplataRacunaTableForm) => {
        /***** 20 *****/
        if (
          this.getUkupanIznos.value.ukupnoOsnovica20 > 0 &&
          avansnaUplata.value.evidentiranaOsnovica20 > 0
        ) {
          // iznos
          this._obradiEvidentiranuOsnovicuAvansnogRacuna(
            this.getUkupanIznos.value.ukupnoOsnovica20,
            this.getUkupanIznos.value.avansiranaOsnovica20,
            avansnaUplata.controls.evidentiranaOsnovica20
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranaOsnovica20,
            this.getUkupanIznos.value.ukupnoOsnovica20,
            this.getUkupanIznos.controls.umanjenaOsnovica20
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranaOsnovica20,
            this.getUkupanIznos.controls.avansiranaOsnovica20
          );
          // pdv
          this._obradiEvidentiranPdvAvansnogRacuna(
            avansnaUplata.value.evidentiranaOsnovica20,
            20,
            avansnaUplata.controls.evidentiranPdv20
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranPdv20,
            this.getUkupanIznos.value.ukupnoPdv20,
            this.getUkupanIznos.controls.ukupnoPdvUmanjenZaAvanse20
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranPdv20,
            this.getUkupanIznos.controls.avansiraniPdv20
          );
        }

        /***** 10 *****/
        if (
          this.getUkupanIznos.value.ukupnoOsnovica10 > 0 &&
          avansnaUplata.value.evidentiranaOsnovica10 > 0
        ) {
          // iznos
          this._obradiEvidentiranuOsnovicuAvansnogRacuna(
            this.getUkupanIznos.value.ukupnoOsnovica10,
            this.getUkupanIznos.value.avansiranaOsnovica10,
            avansnaUplata.controls.evidentiranaOsnovica10
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranaOsnovica10,
            this.getUkupanIznos.value.ukupnoOsnovica10,
            this.getUkupanIznos.controls.umanjenaOsnovica10
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranaOsnovica10,
            this.getUkupanIznos.controls.avansiranaOsnovica10
          );
          // pdv
          this._obradiEvidentiranPdvAvansnogRacuna(
            avansnaUplata.value.evidentiranaOsnovica10,
            10,
            avansnaUplata.controls.evidentiranPdv10
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranPdv10,
            this.getUkupanIznos.value.ukupnoPdv10,
            this.getUkupanIznos.controls.ukupnoPdvUmanjenZaAvanse10
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranPdv10,
            this.getUkupanIznos.controls.avansiraniPdv10
          );
        }

        /***** 8 *****/
        if (
          this.getUkupanIznos.value.ukupnoOsnovica8 > 0 &&
          avansnaUplata.value.evidentiranaOsnovica8 > 0
        ) {
          // iznos
          this._obradiEvidentiranuOsnovicuAvansnogRacuna(
            this.getUkupanIznos.value.ukupnoOsnovica8,
            this.getUkupanIznos.value.avansiranaOsnovica8,
            avansnaUplata.controls.evidentiranaOsnovica8
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranaOsnovica8,
            this.getUkupanIznos.value.ukupnoOsnovica8,
            this.getUkupanIznos.controls.umanjenaOsnovica8
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranaOsnovica8,
            this.getUkupanIznos.controls.avansiranaOsnovica8
          );
          // pdv
          this._obradiEvidentiranPdvAvansnogRacuna(
            avansnaUplata.value.evidentiranaOsnovica8,
            8,
            avansnaUplata.controls.evidentiranPdv8
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranPdv8,
            this.getUkupanIznos.value.ukupnoPdv8,
            this.getUkupanIznos.controls.ukupnoPdvUmanjenZaAvanse8
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranPdv8,
            this.getUkupanIznos.controls.avansiraniPdv8
          );
        }

        /***** AE10 *****/
        if (
          this.getUkupanIznos.value.ukupnoOsnovicaAE10 > 0 &&
          avansnaUplata.value.evidentiranaOsnovicaAE10 > 0
        ) {
          this._obradiEvidentiranuOsnovicuAvansnogRacuna(
            this.getUkupanIznos.value.ukupnoOsnovicaAE10,
            this.getUkupanIznos.value.avansiranaOsnovicaAE10,
            avansnaUplata.controls.evidentiranaOsnovicaAE10
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaAE10,
            this.getUkupanIznos.value.ukupnoOsnovicaAE10,
            this.getUkupanIznos.controls.umanjenaOsnovicaAE10
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaAE10,
            this.getUkupanIznos.controls.avansiranaOsnovicaAE10
          );
        }

        /***** AE20 *****/
        if (
          this.getUkupanIznos.value.ukupnoOsnovicaAE20 > 0 &&
          avansnaUplata.value.evidentiranaOsnovicaAE20 > 0
        ) {
          this._obradiEvidentiranuOsnovicuAvansnogRacuna(
            this.getUkupanIznos.value.ukupnoOsnovicaAE20,
            this.getUkupanIznos.value.avansiranaOsnovicaAE20,
            avansnaUplata.controls.evidentiranaOsnovicaAE20
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaAE20,
            this.getUkupanIznos.value.ukupnoOsnovicaAE20,
            this.getUkupanIznos.controls.umanjenaOsnovicaAE20
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaAE20,
            this.getUkupanIznos.controls.avansiranaOsnovicaAE20
          );
        }

        /***** O *****/
        if (
          this.getRacunFormObject.value.ukupanIznosDto.umanjenaOsnovicaO > 0 &&
          avansnaUplata.value.evidentiranaOsnovicaO > 0
        ) {
          this._obradiEvidentiranuOsnovicuAvansnogRacuna(
            this.getUkupanIznos.value.ukupnoOsnovicaO,
            this.getUkupanIznos.value.avansiranaOsnovicaO,
            avansnaUplata.controls.evidentiranaOsnovicaO
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaO,
            this.getUkupanIznos.value.ukupnoOsnovicaO,
            this.getUkupanIznos.controls.umanjenaOsnovicaO
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaO,
            this.getUkupanIznos.controls.avansiranaOsnovicaO
          );
        }

        /***** E *****/
        if (
          this.getRacunFormObject.value.ukupanIznosDto.umanjenaOsnovicaE > 0 &&
          avansnaUplata.value.evidentiranaOsnovicaE > 0
        ) {
          this._obradiEvidentiranuOsnovicuAvansnogRacuna(
            this.getUkupanIznos.value.ukupnoOsnovicaE,
            this.getUkupanIznos.value.avansiranaOsnovicaE,
            avansnaUplata.controls.evidentiranaOsnovicaE
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaE,
            this.getUkupanIznos.value.ukupnoOsnovicaE,
            this.getUkupanIznos.controls.umanjenaOsnovicaE
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaE,
            this.getUkupanIznos.controls.avansiranaOsnovicaE
          );
        }

        /***** R *****/
        if (
          this.getRacunFormObject.value.ukupanIznosDto.umanjenaOsnovicaR > 0 &&
          avansnaUplata.value.evidentiranaOsnovicaR > 0
        ) {
          this._obradiEvidentiranuOsnovicuAvansnogRacuna(
            this.getUkupanIznos.value.ukupnoOsnovicaR,
            this.getUkupanIznos.value.avansiranaOsnovicaR,
            avansnaUplata.controls.evidentiranaOsnovicaR
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaR,
            this.getUkupanIznos.value.ukupnoOsnovicaR,
            this.getUkupanIznos.controls.umanjenaOsnovicaR
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaR,
            this.getUkupanIznos.controls.avansiranaOsnovicaR
          );
        }

        /***** Z *****/
        if (
          this.getRacunFormObject.value.ukupanIznosDto.umanjenaOsnovicaZ > 0 &&
          avansnaUplata.value.evidentiranaOsnovicaZ > 0
        ) {
          this._obradiEvidentiranuOsnovicuAvansnogRacuna(
            this.getUkupanIznos.value.ukupnoOsnovicaZ,
            this.getUkupanIznos.value.avansiranaOsnovicaZ,
            avansnaUplata.controls.evidentiranaOsnovicaZ
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaZ,
            this.getUkupanIznos.value.ukupnoOsnovicaZ,
            this.getUkupanIznos.controls.umanjenaOsnovicaZ
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaZ,
            this.getUkupanIznos.controls.avansiranaOsnovicaZ
          );
        }

        /***** SS *****/
        if (
          this.getRacunFormObject.value.ukupanIznosDto.umanjenaOsnovicaSS > 0 &&
          avansnaUplata.value.evidentiranaOsnovicaSS > 0
        ) {
          this._obradiEvidentiranuOsnovicuAvansnogRacuna(
            this.getUkupanIznos.value.ukupnoOsnovicaSS,
            this.getUkupanIznos.value.avansiranaOsnovicaSS,
            avansnaUplata.controls.evidentiranaOsnovicaSS
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaSS,
            this.getUkupanIznos.value.ukupnoOsnovicaSS,
            this.getUkupanIznos.controls.umanjenaOsnovicaSS
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaSS,
            this.getUkupanIznos.controls.avansiranaOsnovicaSS
          );
        }

        /***** OE *****/
        if (
          this.getRacunFormObject.value.ukupanIznosDto.umanjenaOsnovicaOE > 0 &&
          avansnaUplata.value.evidentiranaOsnovicaOE > 0
        ) {
          this._obradiEvidentiranuOsnovicuAvansnogRacuna(
            this.getUkupanIznos.value.ukupnoOsnovicaOE,
            this.getUkupanIznos.value.avansiranaOsnovicaOE,
            avansnaUplata.controls.evidentiranaOsnovicaOE
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaOE,
            this.getUkupanIznos.value.ukupnoOsnovicaOE,
            this.getUkupanIznos.controls.umanjenaOsnovicaOE
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaOE,
            this.getUkupanIznos.controls.avansiranaOsnovicaOE
          );
        }

        /***** N *****/
        if (
          this.getRacunFormObject.value.ukupanIznosDto.umanjenaOsnovicaN > 0 &&
          avansnaUplata.value.evidentiranaOsnovicaN > 0
        ) {
          this._obradiEvidentiranuOsnovicuAvansnogRacuna(
            this.getUkupanIznos.value.ukupnoOsnovicaN,
            this.getUkupanIznos.value.avansiranaOsnovicaN,
            avansnaUplata.controls.evidentiranaOsnovicaN
          );
          this._obradiUmanjenIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaN,
            this.getUkupanIznos.value.ukupnoOsnovicaN,
            this.getUkupanIznos.controls.umanjenaOsnovicaN
          );
          this._obradiAvansiranIznosFakture(
            avansnaUplata.value.evidentiranaOsnovicaN,
            this.getUkupanIznos.controls.avansiranaOsnovicaN
          );
        }
      }
    );
    this._izracunajUkupneIznose();
  }

  /**
   * Radimo update evidentirane osnovice na avansnom racunu za iznos koji smo "iskoristili" na fakturi
   * LOGIKA --> da li je osnovica === iskoriscen iznos
   *    DA -> onda evidentiramo osnovica mora biti 0
   *    NE -> obradjujemo evidentiramu osnovicu...
   *
   * @param ukupnaOsnovicaRacuna iznos sa kojim poredimo
   * @param iskorisceno iznos koji smo sve registrovali da je avansno uplacen za tu kategoriju
   * @param evidentiranaOsnovicaCtrl iznos sa avansnog racuna koji smo "iskoristili" na fakturi
   */
  private _obradiEvidentiranuOsnovicuAvansnogRacuna(
    ukupnaOsnovicaRacuna: number,
    iskorisceno: number,
    evidentiranaOsnovicaCtrl: AbstractControl
  ) {
    if (ukupnaOsnovicaRacuna === iskorisceno) {
      // ako smo vec iskoristili koliko nam je potrebno za osnovicu, onda evidentiramo 0
      evidentiranaOsnovicaCtrl.patchValue(0);
      evidentiranaOsnovicaCtrl.setValidators([
        Validators.max(0),
        Validators.min(0),
      ]);
    } else if (
      evidentiranaOsnovicaCtrl.value >= ukupnaOsnovicaRacuna ||
      evidentiranaOsnovicaCtrl.value >= ukupnaOsnovicaRacuna - iskorisceno
    ) {
      // ako je evidentirano vece od osnovice onda uzimamo max moguce
      evidentiranaOsnovicaCtrl.patchValue(ukupnaOsnovicaRacuna - iskorisceno);
      evidentiranaOsnovicaCtrl.setValidators([
        Validators.max(ukupnaOsnovicaRacuna - iskorisceno),
        Validators.min(0),
      ]);
    } else {
      // imamo 'prostora' pa uzimamo ceo iznos
      evidentiranaOsnovicaCtrl.setValidators([
        Validators.max(evidentiranaOsnovicaCtrl.value),
        Validators.min(0),
      ]);
    }

    evidentiranaOsnovicaCtrl.updateValueAndValidity();
  }

  /**
   * Radimo update evidentiranog pdv na avansnom racunu za iznos koji smo "iskoristili" na fakturi
   *
   * @param evidentiranaOsnovica iznos sa avansnog racuna koji smo "iskoristili" na fakturi
   * @param stopaPdv trenutna stopa pdv (10 ili 20)
   * @param evidentiranPdvCtrl iznos pdv-a sa avansnog racuna koji smo "iskoristili" na fakturi
   */
  private _obradiEvidentiranPdvAvansnogRacuna(
    evidentiranaOsnovica: number,
    stopaPdv: 10 | 20 | 8,
    evidentiranPdvCtrl: AbstractControl
  ) {
    evidentiranPdvCtrl.patchValue((evidentiranaOsnovica * stopaPdv) / 100);
    evidentiranPdvCtrl.updateValueAndValidity();
  }

  /**
   * Radimo update fakture  za evidentiran avansni iznos
   * Iznos fakture moze biti 'umanjena osnovica' ili 'pdv umanjen za avanse' (ako pdv postoji...tj ako je avansirano nesto sa 10 ili 20 stopom)
   *
   * @param evidentirano
   * @param ukupno
   * @param umanjenIznosCtrl
   */
  private _obradiUmanjenIznosFakture(
    evidentirano: number,
    ukupno: number,
    umanjenIznosCtrl: AbstractControl
  ) {
    umanjenIznosCtrl.patchValue(ukupno - evidentirano);
    umanjenIznosCtrl.updateValueAndValidity();
  }

  /**
   * Racunamo ukupan avansiran iznos po stopi (i ukupan pdv ako je stopa 10 ili 20)
   * @param evidentiranIznos
   * @param avansiranIznosCtrl
   */
  private _obradiAvansiranIznosFakture(
    evidentiranIznos: number,
    avansiranIznosCtrl: AbstractControl
  ) {
    avansiranIznosCtrl.patchValue(
      sumIfNotNull(avansiranIznosCtrl.value, evidentiranIznos)
    );
    avansiranIznosCtrl.updateValueAndValidity();
  }

  strankaHasAvansneRacune$ = new BehaviorSubject<boolean>(false);

  set setStrankaHasAvansneRacune(value: boolean) {
    this.strankaHasAvansneRacune$.next(value);
  }

  get getStrankaHasAvansneRacune() {
    return this.strankaHasAvansneRacune$.asObservable();
  }

  convertAvansneRacuneTableToAvansnaUplataRacuna(
    avansniRacuniTable: IAvansniRacunTableDto[]
  ): IAvansnaUplataRacunaTableDto[] {
    return avansniRacuniTable.map((x) => ({
      avansniRacunBroj: x.broj,
      avansniRacunId: x.id,
      avansniRacunVrsta: x.vrsta,
      evidentiranPdv8: x.pdv8,
      evidentiranPdv10: x.pdv10,
      evidentiranPdv20: x.pdv20,
      evidentiranaOsnovica8: x.preostaloUkupnoOsnovica8,
      evidentiranaOsnovica10: x.preostaloUkupnoOsnovica10,
      evidentiranaOsnovica20: x.preostaloUkupnoOsnovica20,
      evidentiranaOsnovicaAE10: x.preostaloUkupnoOsnovicaAE10,
      evidentiranaOsnovicaAE20: x.preostaloUkupnoOsnovicaAE20,
      evidentiranaOsnovicaE: x.preostaloUkupnoOsnovicaE,
      evidentiranaOsnovicaN: x.preostaloUkupnoOsnovicaN,
      evidentiranaOsnovicaO: x.preostaloUkupnoOsnovicaO,
      evidentiranaOsnovicaOE: x.preostaloUkupnoOsnovicaOE,
      evidentiranaOsnovicaR: x.preostaloUkupnoOsnovicaR,
      evidentiranaOsnovicaSS: x.preostaloUkupnoOsnovicaSS,
      evidentiranaOsnovicaZ: x.preostaloUkupnoOsnovicaZ,
    }));
  }

  //#endregion Avansne uplate

  //#region Stranka racuna

  set setStrankaracuna(stranka: IStrankaRacunaDto) {
    if (!stranka) {
      return;
    }
    this.getStrankaRacunaFormGroup.patchValue(stranka);

    this.getRacunFormObject.controls.brojTendera.patchValue(
      stranka.brojTendera
    );
    this.getRacunFormObject.controls.brojUgovora.patchValue(
      stranka.brojUgovora
    );

    if (this.getRacunFormObject.value.vrsta === VrstaRacuna.IZLAZNI) {
      this.getRacunFormObject.controls.rokPlacanja.patchValue(
        stranka.rokPlacanjaZaIzlazne
      );
    } else {
      this.getRacunFormObject.controls.rokPlacanja.patchValue(
        stranka.rokPlacanjaZaUlazne
      );
    }

    this.addInformacijaIsporukeStrankeArray(stranka.informacijeIsporuke);
    this.getStrankaRacunaFormGroup.updateValueAndValidity();
  }

  get getStrankaRacunaId(): number | null {
    if (!this.getRacunFormObject) {
      return null;
    }
    return this.getRacunFormObject.value.strankaRacunaDto?.id;
  }

  get getStrankaRacunaFormGroup(): StrankaRacunaForm {
    if (!this.getRacunFormObject) {
      return null;
    }
    return this.getRacunFormObject.getSafe<IStrankaRacunaDto>(
      (x) => x.strankaRacunaDto
    ) as StrankaRacunaForm;
  }

  updateStrankaRacuna(newValue: StrankaRacunaDto) {
    this.getStrankaRacunaFormGroup.patchValue(newValue);
    this.getStrankaRacunaFormGroup.updateValueAndValidity();
  }

  clearStrankaRacuna() {
    // this.getStrankaRacunaFormGroup.patchValue(
    //   this._strankaRacunaFS.GetStrankaRacunaFormGroup()
    // );
    this.getStrankaRacunaFormGroup.reset();
    this.getStrankaRacunaFormGroup.updateValueAndValidity();
  }

  get getInformacijaIsporukeStrankeArray(): FormArray {
    if (!this.getRacunFormObject) {
      return new FormArray([]);
    }
    return this.getStrankaRacunaFormGroup.controls
      .informacijeIsporuke as FormArray;
  }

  addInformacijaIsporukeStrankeArray(value: InformacijaIsporukeDto[]) {
    if (!value) {
      return;
    }
    this.getInformacijaIsporukeStrankeArray.clear();
    value.forEach((v) => {
      this.addInformacijaIsporukeStranke(v);
    });
  }

  addInformacijaIsporukeStranke(value: InformacijaIsporukeDto) {
    this.getInformacijaIsporukeStrankeArray.push(
      this._strankaRacunaFS.getInformacijaIsporukeStrankaFormGroup(value)
    );
  }

  //#endregion Stranka racuna

  //#region Devizni podaci uplate
  private _setDevizniRacunValidation() {
    if (!this.getRacunFormObject) {
      return;
    }

    this.getRacunFormObject
      .get('podaciUplateDevizniDto.bank')
      .setValidators([Validators.required, Validators.maxLength(128)]);
    this.getRacunFormObject
      .get('podaciUplateDevizniDto.bank')
      .updateValueAndValidity();

    this.getRacunFormObject
      .get('podaciUplateDevizniDto.iban')
      .setValidators([Validators.required, Validators.maxLength(33)]);
    this.getRacunFormObject
      .get('podaciUplateDevizniDto.iban')
      .updateValueAndValidity();

    this.getRacunFormObject
      .get('podaciUplateDevizniDto.swift')
      .setValidators([Validators.required, Validators.maxLength(50)]);
    this.getRacunFormObject
      .get('podaciUplateDevizniDto.swift')
      .updateValueAndValidity();

    this.getRacunFormObject
      .get('podaciUplateDevizniDto.accountName')
      .setValidators([Validators.required, Validators.maxLength(256)]);
    this.getRacunFormObject
      .get('podaciUplateDevizniDto.accountName')
      .updateValueAndValidity();

    this.getRacunFormObject
      .get('podaciUplateDevizniDto.accountAddres')
      .setValidators([Validators.required, Validators.maxLength(256)]);
    this.getRacunFormObject
      .get('podaciUplateDevizniDto.accountAddres')
      .updateValueAndValidity();

    this.getRacunFormObject.get('podaciUplateDto.model').reset();
  }

  private _clearDevizniRacunValidation() {
    if (!this.getRacunFormObject) {
      return;
    }
    this.getRacunFormObject
      .get('podaciUplateDevizniDto.bank')
      .clearValidators();
    this.getRacunFormObject
      .get('podaciUplateDevizniDto.bank')
      .updateValueAndValidity();

    this.getRacunFormObject
      .get('podaciUplateDevizniDto.iban')
      .clearValidators();
    this.getRacunFormObject
      .get('podaciUplateDevizniDto.iban')
      .updateValueAndValidity();

    this.getRacunFormObject
      .get('podaciUplateDevizniDto.swift')
      .clearValidators();
    this.getRacunFormObject
      .get('podaciUplateDevizniDto.swift')
      .updateValueAndValidity();

    this.getRacunFormObject
      .get('podaciUplateDevizniDto.accountName')
      .clearValidators();
    this.getRacunFormObject
      .get('podaciUplateDevizniDto.accountName')
      .updateValueAndValidity();

    this.getRacunFormObject
      .get('podaciUplateDevizniDto.accountAddres')
      .clearValidators();
    this.getRacunFormObject
      .get('podaciUplateDevizniDto.accountAddres')
      .updateValueAndValidity();
  }

  //#endregion Devizni podaci uplate

  //#region Ukupan iznos
  get getUkupanIznos(): RacunUkupanIznosForm {
    if (!this.getRacunFormObject) {
      return null;
    }

    return this.getRacunFormObject.getSafe<IRacunUkupanIznosDto>(
      (x) => x.ukupanIznosDto
    ) as RacunUkupanIznosForm;
  }

  /**
   * Racunanje ukupnih vrednosti za racun
   */
  calculateUkupno() {
    //Reset ukupan iznos ako nemamo nijednu stavku
    if (this.getStavkeArray.length === 0) {
      this.getUkupanIznos.reset();
      this.getUkupanIznos.get('ukupnoZaUplatu').patchValue(0);
      return;
    }

    //#region zbir stavki vars
    let zbirStavki20 = null,
      zbirStavki8 = null,
      zbirStavki10 = null,
      zbirStavkiAE10 = null,
      zbirStavkiAE20 = null,
      zbirStavkiO = null,
      zbirStavkiE = null,
      zbirStavkiR = null,
      zbirStavkiZ = null,
      zbirStavkiSS = null,
      zbirStavkiOE = null,
      zbirStavkiN = null,
      zbirStavkiS = null;
    //#endregion zbir stavki vars

    this.getStavkeArray.value.forEach((stavka: IStavkaRacunaDto) => {
      if (stavka.stopaPDV === OSNOVNA_PDV_STOPA) {
        zbirStavki20 = sumIfNotNull(zbirStavki20, stavka.poreskaOsnovica);
        zbirStavkiS = sumIfNotNull(zbirStavkiS, stavka.poreskaOsnovica);
      } else if (stavka.stopaPDV === POSEBNA_PDV_STOPA) {
        zbirStavki10 = sumIfNotNull(zbirStavki10, stavka.poreskaOsnovica);
        zbirStavkiS = sumIfNotNull(zbirStavkiS, stavka.poreskaOsnovica);
      } else if (
        stavka.stopaPDV === POSEBNA_PDV_STOPA_POLJOPRIVREDNO_GAZDINSTVO
      ) {
        zbirStavki8 = sumIfNotNull(zbirStavki8, stavka.poreskaOsnovica);
        zbirStavkiS = sumIfNotNull(zbirStavkiS, stavka.poreskaOsnovica);
      } else {
        switch (stavka.pdvKategorija) {
          case OznakaPdvKategorije.AE10:
            zbirStavkiAE10 = sumIfNotNull(
              zbirStavkiAE10,
              stavka.poreskaOsnovica
            );
            break;

          case OznakaPdvKategorije.AE20:
            zbirStavkiAE20 = sumIfNotNull(
              zbirStavkiAE20,
              stavka.poreskaOsnovica
            );
            break;

          case OznakaPdvKategorije.E:
            zbirStavkiE = sumIfNotNull(zbirStavkiE, stavka.poreskaOsnovica);
            break;

          case OznakaPdvKategorije.N:
            zbirStavkiN = sumIfNotNull(zbirStavkiN, stavka.poreskaOsnovica);
            if (this.getTipRacuna === TipRacuna.AVANSNI_RACUN) {
              zbirStavkiN = -zbirStavkiN;
            }
            break;

          case OznakaPdvKategorije.O:
            zbirStavkiO = sumIfNotNull(zbirStavkiO, stavka.poreskaOsnovica);
            break;

          case OznakaPdvKategorije.OE:
            zbirStavkiOE = sumIfNotNull(zbirStavkiOE, stavka.poreskaOsnovica);
            break;

          case OznakaPdvKategorije.R:
            zbirStavkiR = sumIfNotNull(zbirStavkiR, stavka.poreskaOsnovica);
            break;

          case OznakaPdvKategorije.SS:
            zbirStavkiSS = sumIfNotNull(zbirStavkiSS, stavka.poreskaOsnovica);
            break;

          case OznakaPdvKategorije.Z:
            zbirStavkiZ = sumIfNotNull(zbirStavkiZ, stavka.poreskaOsnovica);
            break;
        }
      }
    });

    this._izracunajUkupneIznose(
      zbirStavki8,
      zbirStavki20,
      zbirStavki10,
      zbirStavkiAE10,
      zbirStavkiAE20,
      zbirStavkiO,
      zbirStavkiE,
      zbirStavkiR,
      zbirStavkiZ,
      zbirStavkiSS,
      zbirStavkiOE,
      zbirStavkiN,
      zbirStavkiS
    );
    this.setShouldUpdateAvans = true;
  }

  private _izracunajUkupneIznose(
    zbirStavki8 = null,
    zbirStavki20 = null,
    zbirStavki10 = null,
    zbirStavkiAE10 = null,
    zbirStavkiAE20 = null,
    zbirStavkiO = null,
    zbirStavkiE = null,
    zbirStavkiR = null,
    zbirStavkiZ = null,
    zbirStavkiSS = null,
    zbirStavkiOE = null,
    zbirStavkiN = null,
    zbirStavkiS = null
  ) {
    if (
      zbirStavki8 !== null ||
      zbirStavki10 !== null ||
      zbirStavki20 !== null ||
      zbirStavkiAE10 !== null ||
      zbirStavkiAE20 !== null ||
      zbirStavkiO !== null ||
      zbirStavkiE !== null ||
      zbirStavkiR !== null ||
      zbirStavkiZ !== null ||
      zbirStavkiS !== null ||
      zbirStavkiOE !== null ||
      zbirStavkiSS !== null ||
      zbirStavkiN !== null
    ) {
      this._updateZbirStavki(
        zbirStavki8,
        zbirStavki20,
        zbirStavki10,
        zbirStavkiAE10,
        zbirStavkiAE20,
        zbirStavkiO,
        zbirStavkiE,
        zbirStavkiR,
        zbirStavkiZ,
        zbirStavkiSS,
        zbirStavkiOE,
        zbirStavkiN,
        zbirStavkiS
      );
    }

    this._updateUkupnaOsnovica();

    this._updateUkupanAvansniIznos();

    if (this.getValutaTextValue === SERBIA_CURRENCY_ALPHA_CHAR) {
      this._resetUkupnaOsnovicaRsd();
    } else {
      this._updateUkupnaOsnovicaRsd(
        this.getRacunFormObject.value.podaciUplateDevizniDto.srednjiKursNaDan
      );
    }
    this._updateUmanjeneOsnovice();
    this.updateUkupnoZaUplatu();

    this.getUkupanIznos.updateValueAndValidity();
  }

  private _updateZbirStavki(
    zbirStavki8: number | null,
    zbirStavki20: number | null,
    zbirStavki10: number | null,
    zbirStavkiAE10: number | null,
    zbirStavkiAE20: number | null,
    zbirStavkiO: number | null,
    zbirStavkiE: number | null,
    zbirStavkiR: number | null,
    zbirStavkiZ: number | null,
    zbirStavkiSS: number | null,
    zbirStavkiOE: number | null,
    zbirStavkiN: number | null,
    zbirStavkiS: number | null
  ) {
    this.getUkupanIznos.patchValue({
      zbirStavki8: zbirStavki8,
      zbirStavki10: zbirStavki10,
      zbirStavki20: zbirStavki20,
      zbirStavkiAE10: zbirStavkiAE10,
      zbirStavkiAE20: zbirStavkiAE20,
      zbirStavkiE: zbirStavkiE,
      zbirStavkiN: zbirStavkiN,
      zbirStavkiR: zbirStavkiR,
      zbirStavkiOE: zbirStavkiOE,
      zbirStavkiO: zbirStavkiO,
      zbirStavkiS: zbirStavkiS,
      zbirStavkiSS: zbirStavkiSS,
      zbirStavkiZ: zbirStavkiZ,
    });
  }

  private _updateUkupnaOsnovica() {
    this.getUkupanIznos.patchValue({
      ukupnoOsnovica8: subIfNotNull(
        this.getUkupanIznos.value.zbirStavki8,
        this.getUkupanIznos.value.smanjenjeNaknade8
      ),
      ukupnoOsnovica10: subIfNotNull(
        this.getUkupanIznos.value.zbirStavki10,
        this.getUkupanIznos.value.smanjenjeNaknade10
      ),
      ukupnoOsnovica20: subIfNotNull(
        this.getUkupanIznos.value.zbirStavki20,
        this.getUkupanIznos.value.smanjenjeNaknade20
      ),
      ukupnoPdv8:
        this.getUkupanIznos.value.zbirStavki8 !== 0
          ? +(
              (subIfNotNull(
                this.getUkupanIznos.value.zbirStavki8,
                this.getUkupanIznos.value.smanjenjeNaknade8
              ) *
                POSEBNA_PDV_STOPA_POLJOPRIVREDNO_GAZDINSTVO) /
              100
            ).toFixed(2)
          : null,
      ukupnoPdv10:
        this.getUkupanIznos.value.zbirStavki10 !== 0
          ? +(
              (subIfNotNull(
                this.getUkupanIznos.value.zbirStavki10,
                this.getUkupanIznos.value.smanjenjeNaknade10
              ) *
                POSEBNA_PDV_STOPA) /
              100
            ).toFixed(2)
          : null,
      ukupnoPdv20:
        this.getUkupanIznos.value.zbirStavki20 !== 0
          ? +(
              (subIfNotNull(
                this.getUkupanIznos.value.zbirStavki20,
                this.getUkupanIznos.value.smanjenjeNaknade20
              ) *
                OSNOVNA_PDV_STOPA) /
              100
            ).toFixed(2)
          : null,
      ukupnoOsnovicaAE10: subIfNotNull(
        this.getUkupanIznos.value.zbirStavkiAE10,
        this.getUkupanIznos.value.smanjenjeNaknadeAE10
      ),
      ukupnoOsnovicaAE20: subIfNotNull(
        this.getUkupanIznos.value.zbirStavkiAE20,
        this.getUkupanIznos.value.smanjenjeNaknadeAE20
      ),
      ukupnoOsnovicaE: subIfNotNull(
        this.getUkupanIznos.value.zbirStavkiE,
        this.getUkupanIznos.value.smanjenjeNaknadeE
      ),
      ukupnoOsnovicaN: subIfNotNull(
        this.getUkupanIznos.value.zbirStavkiN,
        this.getUkupanIznos.value.smanjenjeNaknadeN
      ),
      ukupnoOsnovicaOE: subIfNotNull(
        this.getUkupanIznos.value.zbirStavkiOE,
        this.getUkupanIznos.value.smanjenjeNaknadeOE
      ),
      ukupnoOsnovicaO: subIfNotNull(
        this.getUkupanIznos.value.zbirStavkiO,
        this.getUkupanIznos.value.smanjenjeNaknadeO
      ),
      ukupnoOsnovicaR: subIfNotNull(
        this.getUkupanIznos.value.zbirStavkiR,
        this.getUkupanIznos.value.smanjenjeNaknadeR
      ),
      ukupnoOsnovicaSS: subIfNotNull(
        this.getUkupanIznos.value.zbirStavkiSS,
        this.getUkupanIznos.value.smanjenjeNaknadeSS
      ),
      ukupnoOsnovicaZ: subIfNotNull(
        this.getUkupanIznos.value.zbirStavkiZ,
        this.getUkupanIznos.value.smanjenjeNaknadeZ
      ),
    });
  }

  private _updateUkupnaOsnovicaRsd(currencyValue: number) {
    this.getUkupanIznos.patchValue({
      rsdUkupnoOsnovica8: multiplyIfNotNullConditionally(
        this.getUkupanIznos.value.zbirStavki8,
        currencyValue
      ),
      rsdUkupnoOsnovica10: multiplyIfNotNullConditionally(
        this.getUkupanIznos.value.zbirStavki10,
        currencyValue
      ),
      rsdUkupnoOsnovica20: multiplyIfNotNullConditionally(
        this.getUkupanIznos.value.zbirStavki20,
        currencyValue
      ),
      rsdUkupnoPdv10:
        this.getUkupanIznos.value.zbirStavki10 > 0
          ? +(
              multiplyIfNotNullConditionally(
                this.getUkupanIznos.value.zbirStavki10,
                currencyValue,
                POSEBNA_PDV_STOPA
              ) / 100
            ).toFixed(2)
          : null,
      rsdUkupnoPdv8:
        this.getUkupanIznos.value.zbirStavki8 > 0
          ? +(
              multiplyIfNotNullConditionally(
                this.getUkupanIznos.value.zbirStavki8,
                currencyValue,
                POSEBNA_PDV_STOPA_POLJOPRIVREDNO_GAZDINSTVO
              ) / 100
            ).toFixed(2)
          : null,
      rsdUkupnoPdv20:
        this.getUkupanIznos.value.zbirStavki20 > 0
          ? +(
              multiplyIfNotNullConditionally(
                this.getUkupanIznos.value.zbirStavki20,
                currencyValue,
                OSNOVNA_PDV_STOPA
              ) / 100
            ).toFixed(2)
          : null,
      rsdUkupnoOsnovicaAE10: multiplyIfNotNullConditionally(
        this.getUkupanIznos.value.zbirStavkiAE10,
        currencyValue
      ),
      rsdUkupnoOsnovicaAE20: multiplyIfNotNullConditionally(
        this.getUkupanIznos.value.zbirStavkiAE20,
        currencyValue
      ),
      rsdUkupnoOsnovicaE: multiplyIfNotNullConditionally(
        this.getUkupanIznos.value.zbirStavkiE,
        currencyValue
      ),
      rsdUkupnoOsnovicaN: multiplyIfNotNullConditionally(
        this.getUkupanIznos.value.zbirStavkiN,
        currencyValue
      ),
      rsdUkupnoOsnovicaOE: multiplyIfNotNullConditionally(
        this.getUkupanIznos.value.zbirStavkiOE,
        currencyValue
      ),
      rsdUkupnoOsnovicaO: multiplyIfNotNullConditionally(
        this.getUkupanIznos.value.zbirStavkiO,
        currencyValue
      ),
      rsdUkupnoOsnovicaR: multiplyIfNotNullConditionally(
        this.getUkupanIznos.value.zbirStavkiR,
        currencyValue
      ),
      rsdUkupnoOsnovicaSS: multiplyIfNotNullConditionally(
        this.getUkupanIznos.value.zbirStavkiSS,
        currencyValue
      ),
      rsdUkupnoOsnovicaZ: multiplyIfNotNullConditionally(
        this.getUkupanIznos.value.zbirStavkiZ,
        currencyValue
      ),
    });
  }

  private _resetUkupnaOsnovicaRsd() {
    this.getUkupanIznos.patchValue({
      rsdUkupnoOsnovica8: null,
      rsdUkupnoOsnovica10: null,
      rsdUkupnoOsnovica20: null,
      rsdUkupnoPdv8: null,
      rsdUkupnoPdv10: null,
      rsdUkupnoPdv20: null,
      rsdUkupnoOsnovicaAE10: null,
      rsdUkupnoOsnovicaAE20: null,
      rsdUkupnoOsnovicaE: null,
      rsdUkupnoOsnovicaN: null,
      rsdUkupnoOsnovicaOE: null,
      rsdUkupnoOsnovicaO: null,
      rsdUkupnoOsnovicaR: null,
      rsdUkupnoOsnovicaSS: null,
      rsdUkupnoOsnovicaZ: null,
    });
  }

  private _updateUkupanAvansniIznos() {
    this.getUkupanIznos.patchValue({
      // ukupan avansiran iznos
      ukupanAvansiranIznos: sumIfNotNull(
        this.getUkupanIznos.value.avansiraniPdv8,
        this.getUkupanIznos.value.avansiraniPdv10,
        this.getUkupanIznos.value.avansiraniPdv20,
        this.getUkupanIznos.value.avansiranaOsnovicaS,
        this.getUkupanIznos.value.avansiranaOsnovica8,
        this.getUkupanIznos.value.avansiranaOsnovica10,
        this.getUkupanIznos.value.avansiranaOsnovica20,
        this.getUkupanIznos.value.avansiranaOsnovicaAE10,
        this.getUkupanIznos.value.avansiranaOsnovicaAE20,
        this.getUkupanIznos.value.avansiranaOsnovicaO,
        this.getUkupanIznos.value.avansiranaOsnovicaE,
        this.getUkupanIznos.value.avansiranaOsnovicaR,
        this.getUkupanIznos.value.avansiranaOsnovicaZ,
        this.getUkupanIznos.value.avansiranaOsnovicaSS,
        this.getUkupanIznos.value.avansiranaOsnovicaOE,
        this.getUkupanIznos.value.avansiranaOsnovicaN
      ),
    });
  }

  private _updateUmanjeneOsnovice() {
    this.getUkupanIznos.patchValue({
      umanjenaOsnovica20: subIfNotNull(
        this.getUkupanIznos.value.ukupnoOsnovica20,
        this.getUkupanIznos.value.avansiranaOsnovica20
      ),
      umanjenaOsnovica10: subIfNotNull(
        this.getUkupanIznos.value.ukupnoOsnovica10,
        this.getUkupanIznos.value.avansiranaOsnovica10
      ),
      umanjenaOsnovica8: subIfNotNull(
        this.getUkupanIznos.value.ukupnoOsnovica8,
        this.getUkupanIznos.value.avansiranaOsnovica8
      ),
      umanjenaOsnovicaAE10: subIfNotNull(
        this.getUkupanIznos.value.ukupnoOsnovicaAE10,
        this.getUkupanIznos.value.avansiranaOsnovicaAE10
      ),
      umanjenaOsnovicaAE20: subIfNotNull(
        this.getUkupanIznos.value.ukupnoOsnovicaAE20,
        this.getUkupanIznos.value.avansiranaOsnovicaAE20
      ),
      umanjenaOsnovicaE: subIfNotNull(
        this.getUkupanIznos.value.ukupnoOsnovicaE,
        this.getUkupanIznos.value.avansiranaOsnovicaE
      ),
      umanjenaOsnovicaN: subIfNotNull(
        this.getUkupanIznos.value.ukupnoOsnovicaN,
        this.getUkupanIznos.value.avansiranaOsnovicaN
      ),
      umanjenaOsnovicaO: subIfNotNull(
        this.getUkupanIznos.value.ukupnoOsnovicaO,
        this.getUkupanIznos.value.avansiranaOsnovicaO
      ),
      umanjenaOsnovicaOE: subIfNotNull(
        this.getUkupanIznos.value.ukupnoOsnovicaOE,
        this.getUkupanIznos.value.avansiranaOsnovicaOE
      ),
      umanjenaOsnovicaR: subIfNotNull(
        this.getUkupanIznos.value.ukupnoOsnovicaR,
        this.getUkupanIznos.value.avansiranaOsnovicaR
      ),
      umanjenaOsnovicaSS: subIfNotNull(
        this.getUkupanIznos.value.ukupnoOsnovicaSS,
        this.getUkupanIznos.value.avansiranaOsnovicaSS
      ),
      umanjenaOsnovicaZ: subIfNotNull(
        this.getUkupanIznos.value.ukupnoOsnovicaZ,
        this.getUkupanIznos.value.avansiranaOsnovicaZ
      ),
      ukupnoPdvUmanjenZaAvanse8: subIfNotNull(
        this.getUkupanIznos.value.ukupnoPdv8,
        this.getUkupanIznos.value.avansiraniPdv8
      ),
      ukupnoPdvUmanjenZaAvanse10: subIfNotNull(
        this.getUkupanIznos.value.ukupnoPdv10,
        this.getUkupanIznos.value.avansiraniPdv10
      ),
      ukupnoPdvUmanjenZaAvanse20: subIfNotNull(
        this.getUkupanIznos.value.ukupnoPdv20,
        this.getUkupanIznos.value.avansiraniPdv20
      ),
    });
  }

  updateUkupnoZaUplatu() {
    this.getUkupanIznos.patchValue({
      ukupnoZaUplatu: sumIfNotNull(
        this.getUkupanIznos.value.umanjenaOsnovica8,
        this.getUkupanIznos.value.umanjenaOsnovica10,
        this.getUkupanIznos.value.umanjenaOsnovica20,
        this.getUkupanIznos.value.umanjenaOsnovicaAE10,
        this.getUkupanIznos.value.umanjenaOsnovicaAE20,
        this.getUkupanIznos.value.umanjenaOsnovicaO,
        this.getUkupanIznos.value.umanjenaOsnovicaE,
        this.getUkupanIznos.value.umanjenaOsnovicaR,
        this.getUkupanIznos.value.umanjenaOsnovicaZ,
        this.getUkupanIznos.value.umanjenaOsnovicaSS,
        this.getUkupanIznos.value.umanjenaOsnovicaOE,
        this.getUkupanIznos.value.umanjenaOsnovicaN,
        this.getUkupanIznos.value.ukupnoPdvUmanjenZaAvanse8,
        this.getUkupanIznos.value.ukupnoPdvUmanjenZaAvanse10,
        this.getUkupanIznos.value.ukupnoPdvUmanjenZaAvanse20,
        this.getUkupanIznos.value.iznosZaZaokruzivanje
      ),
    });
  }

  private _clearAvansiraneIznose() {
    this.getUkupanIznos.patchValue({
      avansiranaOsnovica8: null,
      avansiranaOsnovica10: null,
      avansiranaOsnovica20: null,
      avansiranaOsnovicaAE10: null,
      avansiranaOsnovicaAE20: null,
      avansiranaOsnovicaE: null,
      avansiranaOsnovicaN: null,
      avansiranaOsnovicaO: null,
      avansiranaOsnovicaOE: null,
      avansiranaOsnovicaR: null,
      avansiranaOsnovicaS: null,
      avansiranaOsnovicaSS: null,
      avansiranaOsnovicaZ: null,
      avansiraniPdv8: null,
      avansiraniPdv10: null,
      avansiraniPdv20: null,
    });
    this.getUkupanIznos.controls.ukupanAvansiranIznos.updateValueAndValidity();
  }

  shouldUpdateAvans$ = new BehaviorSubject<boolean>(false);

  get getShouldUpdateAvans(): Observable<boolean> {
    return this.shouldUpdateAvans$.asObservable();
  }

  set setShouldUpdateAvans(value: boolean) {
    this.shouldUpdateAvans$.next(value);
  }

  //#endregion Ukupan iznos

  //#region Neobradjen sef racun
  initRacunFormFromNeobradjenRacun(
    model: INeobradjenRacunDto,
    formConfiguration: IGetFormConfigurationResponse
  ): RacunForm {
    if (this.getRacunFormObject?.value) {
      return;
    }
    const racunForm = this._racunFS.GetBaseRacunFormGroup(
      model.tipRacunaDto.tip,
      VrstaRacuna.ULAZNI,
      TIP_RACUNA_TO_TIP_ENTITETA[model.tipRacunaDto.tip],
      formConfiguration,
      {
        ulazniRacunIdSef: model.sefId,
        originalanBroj: model.broj,
        strankaRacunaDto: new StrankaRacunaDto({
          naziv: model.dobavljac,
          mb: model.dobavljacMB,
          pib: model.dobavljacPib,
          tipStranke: TipStranke.PRAVNO_LICE,
          // ToDo: dodati i JBKJS
        }),
        datumIzdavanja: model.datumIzdavanja,
        datumValute: model.datumValute,
        datumPrometa: model.datumPrometa,
        podaciUplateDto: new PodaciUplateDto({
          model: model.brojModela,
          pozivNaBroj: model.pozivNaBroj,
          sifraPlacanja: model.sifraPlacanja,
        }),
        brojNarudzbenice: model.brojNarudzbenice,
        brojTendera: model.brojTendera,
        brojUgovora: model.brojUgovora,
        avansneUplateRacunaTableDto: model.avansneUplateRacunaTableDto,
        ukupanIznosDto: model.ukupanIznosDto,
        stavkeDto: model.stavkeDto.map(
          (s, i) =>
            new StavkaRacunaDto({
              redniBroj: i + 1,
              pdvKategorija: OznakaPdvKategorije[s.pdvKategorija],
              razlogPdvIzuzecaId: s.razlogPdvIzuzecaId,
              stopaPDV: s.pdvStopa,
            })
        ),
        mestoIzdavanja: model.mestoIzdavanjaIPrometa,
        mestoPrometa: model.mestoIzdavanjaIPrometa,
        status: model.statusRacuna,
        relevantniDokumentListItemDtoArray: model.pdfDokumentList,
        tekuciRacunId: model.tekuciRacunId,
      }
    );

    this.racunForm$.next(racunForm);
    this.setJeDomaciRacun = true;

    return this.getRacunFormObject;
  }

  //#endregion Neobradjen sef racun

  //#region Validators
  setStrankaRequiredValidator() {
    this.getRacunFormObject?.controls.strankaRacunaDto
      .get('naziv')
      .setValidators([Validators.required]);

    this.getRacunFormObject?.controls.strankaRacunaDto
      .get('naziv')
      .updateValueAndValidity();
  }

  resetStrankaRequiredValidator() {
    this.getRacunFormObject.controls.strankaRacunaDto
      .get('naziv')
      .clearValidators();

    this.getRacunFormObject.controls.strankaRacunaDto
      .get('naziv')
      .updateValueAndValidity();
  }

  //#endregion Validators

  resetService() {
    this.setRacunForm = null;
    this.setStrankaHasAvansneRacune = false;
    this.setOznakePredmetaStavke = [];
    this.setShowFizickoLiceCreate = null;
    this.setPerformSearchFizickogLica = null;
    this.setHasVezaniRacun = null;
    this.setOdabraneAvansneUplateZaRacunNonModified = [];
    this.setHasRegistrovanuAvansnuUplatu = false;
    this.setShouldUpdateAvans = false;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}

export declare type StavkeRacunaForm = FormGroupTypeSafe<IStavkaRacunaDto>;
export declare type RacunForm = FormGroupTypeSafe<IRacunDto>;
export declare type AvansnaUplataRacunaTableForm = FormGroupTypeSafe<IAvansnaUplataRacunaTableDto>;
export declare type RacunUkupanIznosForm = FormGroupTypeSafe<IRacunUkupanIznosDto>;
export declare type StrankaRacunaForm = FormGroupTypeSafe<IStrankaRacunaDto>;
